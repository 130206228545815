import React, { useState } from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CreateUser = ({ callbackFunction }) => {
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);

  const createUserHandler = () => {
    if (firstName.length === 0 || secondName.length === 0 || email.length === 0) {
      return;
    }
    setLoading(true);

    const data = {
      firstName,
      secondName,
      email
    }

    // Call the function that creates the user
    try {
      callbackFunction(data);
    } finally { 
      setLoading(false);
    }
  }

  return (
    <div className={'mb-4'}>
      <FloatingLabel className={'mb-2'} label={'First Name'}>
        <Form.Control
          placeholder='First Name'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)} />
      </FloatingLabel>
      <FloatingLabel className={'mb-2'} label={'Last Name'}>
        <Form.Control
          placeholder='Last Name'
          value={secondName}
          onChange={(e) => setSecondName(e.target.value)} />
      </FloatingLabel>

      <FloatingLabel className={'mb-2'} label={'E-Mail'}>
        <Form.Control
          placeholder={'E-Mail...'}
          value={email}
          onChange={(e) => setEmail(e.target.value)} />
      </FloatingLabel>

      <Button
        className={'w-100'}
        onClick={createUserHandler}
        disabled={loading}
        variant={firstName.length > 0 && secondName.length > 0 && email.length > 0 ? 'success' : 'secondary'}>Create User</Button>
    </div>
  )
}

CreateUser.propTypes = {
  /**
   * The callback function that gets called when the user clicks on create
   * user. It is expected to take the user with the keys name and email as an input
   */
  callbackFunction: PropTypes.func.isRequired
}

export default CreateUser;